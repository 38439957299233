import React from "react";
import Typewriter from "typewriter-effect";
import "./App.css";

function App() {
  return (
    <div className="app">
      <div className="container-content">
        <div className="content-box">
          <h1 className="title">Arctic Bastion</h1>
          <Typewriter
            options={{ delay: 50 }}
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  `Digital assets trading, proprietary trading, statistical arbitrage, liquidity, compliance & security.`
                )
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .callFunction(() => {
                  console.log("All strings were deleted");
                })
                .start();
            }}
          />
        </div>
        <p className="discription">
          Copyright © Arctic Bastion Labs Ltd or one of its affiliates. All
          rights reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
